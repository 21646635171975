import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import Example, { GroupRadioButtonsExample } from '../../components/examples/radio';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MWC to Swarm-UI Migration Docs`}</h1>
    <hr></hr>
    <br />
Below are a few examples of migrating simple and complex
components from meetup-web-components to Swarm-UI.
    <br />
    <br />
    <br />
    <br />
    <h3>{`RadioButton example`}</h3>
    <hr></hr>
    <br />
    <h4>{`Before, using meetup-web-components:`}</h4>
    <pre><code parentName="pre" {...{}}>{`import RadioButton from 'meetup-web-components/lib/forms/RadioButton';

<RadioButton
    name="option"
    value="1"
    checked={true}
    className={text('className', '')}
    label={text('label', 'This is a radio')}
/>
`}</code></pre>
    <br />
    <br />
    <h4>{`After, using swarm-ui:`}</h4>
    <pre><code parentName="pre" {...{}}>{`import { Button } from '@meetup/swarm-components';

<Radio
    name="swarm"
    label="swarm"
    value="1"
    checked={true}
    onChange={(e) => alert(e.target.value)} />
`}</code></pre>
    <br />
    <br />
    <h4>{`Swarm UI examples`}</h4>
    <Example mdxType="Example" />
    <br />
    <br />
    <h3>{`RadioButtonGroup example`}</h3>
    <hr></hr>
    <br />
    <h4>{`Before, using meetup-web-components:`}</h4>
    <pre><code parentName="pre" {...{}}>{`import RadioButton from 'meetup-web-components/lib/forms/RadioButton';
import RadioButtonGroup from 'meetup-web-components/lib/forms/RadioButtonGroup';

    <RadioButtonGroup
        name="radioButtonGroup"
        onChange={action('radio button change')}
        className={classNameKnob}
        direction={directionKnob}
        selectedValue={selectedValueKnob}
    >
        <RadioButton value="one" label="Option 1" name="radioButton" />
        <RadioButton value="two" label="Option 2" name="radioButton" />
        <RadioButton value="three" label="Option 3" name="radioButton" />
    </RadioButtonGroup>
`}</code></pre>
    <br />
    <br />
    <h4>{`After, using swarm-ui:`}</h4>
    <pre><code parentName="pre" {...{}}>{`export const GroupRadioButtonsExample = () => {
  const [shape, setShape] = React.useState('circle');

  React.useEffect(() => console.log(shape));

  return(
    <>
      <Radio
        name='shape'
        label="Circle"
        id='circle'
        value='circle'
        onChange={(e) => setShape(e.target.value)}
        checked={shape === 'circle'}
      />
      <Radio
        name="shape"
        label="Square"
        id='square'
        value='square'
        onChange={(e) => setShape(e.target.value)}
        checked={shape === 'square'}
      />
      <Radio
        name="shape"
        label="Triangle"
        id='triangle'
        value='triangle'
        onChange={(e) => setShape(e.target.value)}
        checked={shape === 'triangle'}
      />
    </>
  );
};
`}</code></pre>
    <br />
    <br />
    <h4>{`Swarm UI example`}</h4>
    <GroupRadioButtonsExample mdxType="GroupRadioButtonsExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      